export default function Paperclip() {
  return (
    <svg fill='#ddd6c7' width='32px' height='32px' viewBox='0 0 1920 1920' xmlns='http://www.w3.org/2000/svg'>
      <g id='SVGRepo_bgCarrier' strokeWidth='0' />

      <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />

      <g id='SVGRepo_iconCarrier'>
        {" "}
        <path
          d='M1748.318 252.722c-229.016-229.016-601.528-228.91-830.438 0l-792.748 792.749C44.522 1126.187 0 1233.38 0 1347.409c0 114.135 44.522 221.33 125.132 301.939 161.432 161.432 442.658 161.432 603.983 0l717.371-717.264c103.885-103.992 103.885-273.218-.213-377.53-104.099-103.992-273.646-103.885-377.424.107l-603.983 603.983 151.076 150.97 603.77-603.877c20.926-20.713 54.878-20.927 75.591-.214 20.82 20.927 20.82 54.879.107 75.698L578.146 1498.38c-80.716 80.716-221.329 80.716-302.045 0-40.358-40.358-62.566-93.956-62.566-150.97 0-57.013 22.208-110.61 62.566-150.969l792.748-792.749c145.631-145.417 382.655-145.63 528.5 0 145.63 145.631 145.63 382.869 0 528.5l-754.953 755.06 150.969 150.969 754.953-755.06c228.91-228.91 228.91-601.422 0-830.438'
          fillRule='evenodd'
        />{" "}
      </g>
    </svg>
  );
}
